import React from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import '../styles/index.scss'

import MenubarComponent from '../components/MenubarComponent'
import FooterComponent from '../components/FooterComponent'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

export default function Cars({ data }) {
  const image = getImage(data.file.childImageSharp.gatsbyImageData)
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      >
        <title>{data.site.siteMetadata.title} - OGŁOSZENIA</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta
          property="og:description"
          content={data.site.siteMetadata.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <div className="overflow-hidden	flex">
        <MenubarComponent active="cars" />
      </div>

      <div className="mt-24 mb-52 mx-auto px-6 md:px-8 xl:px-16 max-w-screen-2xl flex-no-wrap flex gap-8 flex-col my-16">
        <GatsbyImage
          className="h-48 md:h-72 xl:h-80 rounded-sm overflow-hidden"
          image={image}
          alt="m-auto plac"
        />
        <div className="header-text-decorator">
          <div className="ml-4">
            <h2 className="blue-dark-color text-2xl xl:text-3xl font-bold">
              OFERTA
            </h2>
            <h3 className="text-gray-500 text-sm text-justify mt-1">
              Wszystkie ogłoszenia
            </h3>
          </div>
        </div>

        {data.allContentfulMauto.edges.length === 0 ? (
          <div className="text-center w-full">
            <h3 className="font-bold text-xl">
              Chwilowo brak ogłoszeń na naszej stronie
            </h3>
            <p className="text-gray-600 mt-2">Skontaktuj się z nami!</p>
          </div>
        ) : (
          <>
            <div className="cars-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-6 gap-6">
              {data.allContentfulMauto.edges.reverse().map((car) => (
                <Link to={`/${car.node.id}`} key={car.node.id}>
                  <div
                    className="shadow-md rounded-sm overflow-hidden hover:bg-gray-50"
                    id={car.node.id}
                  >
                    <GatsbyImage
                      className="h-64 overflow-hidden"
                      image={getImage(car.node.zdjecia[0])}
                      alt="mauto oferta"
                    />

                    <div className="p-4 py-4">
                      <div className="flex flex-direction-row justify-between content-center">
                        <h2 className="text-3xl font-bold">
                          {car.node.marka}
                          <span className="text-gray-600 mx-2 text-lg">/</span>
                          <span className="text-gray-600 text-lg">
                            {car.node.model}
                          </span>
                        </h2>

                        <p className="text-yellow-600 text-base font-bold mt-2">
                          {car.node.cena}
                          <span className="font-normal"> PLN</span>
                        </p>
                      </div>
                      <p className="text-gray-500 text-sm mt-2 mb-4">
                        {car.node.moc} KM | {car.node.paliwo} |{' '}
                        {car.node.przebieg} km | {car.node.rok} |{' '}
                        {car.node.skrzynia}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
      <FooterComponent />
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        url
        title
        keywords
        author
        description
      }
    }
    file(relativePath: { eq: "cars_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allContentfulMauto(
      filter: { contentful_id: { ne: "xiRXpu693Fo9h4x31HMV6" } }
      sort: { fields: createdAt }
    ) {
      edges {
        node {
          id
          marka
          model
          paliwo
          przebieg
          rok
          skrzynia
          cena
          moc
          tagi
          zdjecia {
            gatsbyImageData
          }
        }
      }
    }
  }
`
